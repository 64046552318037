.pagination-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 50%;
}

.pagination-controls div,
.pagination-controls select {
    margin: 10px 0;
}

.pagination-controls .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.pagination-controls button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.pagination-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}