.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Goldman', cursive;
  font-style: italic;
  color:#005500
}

.scorecardDatabase h1, .scorecardDatabase h2, .scorecardDatabase h3, .scorecardDatabase h4, .scorecardDatabase h5, .scorecardDatabase h6 {
  font-family: cursive;
  font-style: italic;
  color:#005500
}
